import Head from "next/head";

export default function Layout({ children, title }: React.ComponentProps<"div">) {
  return (
    <>
      <Head>
        <title>{title} - Treater</title>
      </Head>
      {children}
    </>
  );
}
